import * as bookingList from './BookingListAction';
import * as bookingSelect from './BookingSelectAction';
import * as checkinRequest from './CheckinRequestAction';
import * as locationServiceConfig from './LocationServiceConfigAction';
import * as userConfig from './UserConfigAction';

export const bookingListAction = bookingList;
export const bookingSelectAction = bookingSelect;
export const checkinRequestAction = checkinRequest;
export const locationServiceConfigAction = locationServiceConfig;
export const userConfigAction = userConfig;