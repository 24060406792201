import axios from 'axios';
// import _ from 'lodash';

// config
const request = axios.create({
  baseURL: process.env.REACT_APP_IMGATE_BACKEND_BASEURL,
});

const headers = (header) => {
  const apiKey = sessionStorage.getItem('apiKey');
  return {
    headers: {
      ...apiKey && {'Authorization': `Bearer ${apiKey}`},
      ...header && header,
    }
  };
};

// const querystring = (query) => {
//   return {
//     params: query
//   };
// };

// const removeNullInObject = (obj) => {
//   return _.pickBy(obj, function (value, key) {
//     return !_.isEmpty(value);
//   });
// }

export const listBooking = ({name, phoneNumber}) => {
  return request.get('/multifamily/users/elysian/bookings', {
    ...headers(),
    params: {
      rsv_name: name,
      rsv_phone_no: phoneNumber,
    },
  });
};

export const listRoom = ({rsvNo, condoPyungCode}) => {
  return request.get('/multifamily/users/elysian/rooms', {
    ...headers(),
    params: {
      rsv_no: rsvNo,
      condo_pyung_code: condoPyungCode,
    },
  });
};

export const checkIn = ({rsvNo, name, phoneNumber, roomNumber, personCount, isMarketing}) => {
  return request.get('/multifamily/users/elysian/checkin', {
    ...headers(),
    params: {
      rsv_no: rsvNo,
      guest_name: name,
      guest_phone_no: phoneNumber,
      room_no: roomNumber,
      person_count: personCount,
      marketing_yn: isMarketing,
    },
  });
};

export const issueMobilekey = ({rsvNo, roomNumber}) => {
  return request.post('/multifamily/users/elysian/mobilekeys',
    {
      rsv_no: rsvNo,
      room_no: roomNumber,
    },
    headers(),
  );
};

export const sendQuestionnaire = ({rsvNo, roomNumber, guestName1, guestBirthday1, guestName2, guestBirthday2, guestName3, guestBirthday3, guestName4, guestBirthday4, question1, question2, question3, question4}) => {
  return request.post('/multifamily/users/elysian/corona',
    {
      rsv_no: rsvNo,
      room_no: roomNumber,
      guest_name1: guestName1,
      guest_birthday1: guestBirthday1,
      guest_name2: guestName2,
      guest_birthday2: guestBirthday2,
      guest_name3: guestName3,
      guest_birthday3: guestBirthday3,
      guest_name4: guestName4,
      guest_birthday4: guestBirthday4,
      question1: question1,
      question2: question2,
      question3: question3,
      question4: question4,
    },
    headers(),
  );
};