import * as types from '../constants';

export const setBookingAuthRequest = (bookingAuthRequestParams) => ({
  type: types.SET_BOOKING_AUTH_REQUEST,
  payload: bookingAuthRequestParams,
});

export const setIsSkipBookingAuth = (isSkipBookingAuth) => ({
  type: types.SET_SKIP_BOOKING_AUTH,
  payload: isSkipBookingAuth,
});