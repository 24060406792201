import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import MainLayout from '../components/layouts/MainLayout';
import BookingList from '../components/views/BookingList';
import { alertFail } from '../components/Alert';
import * as api from '../api';
import { bookingListAction, bookingSelectAction, userConfigAction } from '../stores/actions';

const BookingListContainer = ({
  history,
}) => {
  const [ isOpenModalLocationService, setIsModalLocationServiceVisible ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const { bookingListItems } = useSelector(state => state.bookingList);
  const { name, phoneNumber } = useSelector(state => state.userConfig);
  const dispatch = useDispatch();

  const listBooking = async () => {
    try {
      const { data: bookings } = await api.listBooking({name, phoneNumber});
      if (bookings.return !== '0' && !bookings.data) {
        dispatch(bookingListAction.setBookingList([]));
        alertFail(bookings.msg, 3);
      }
      else {
        const mergedBookings = bookings.data.map(booking => {
          booking.user_name = name;
          booking.phone_number = phoneNumber;
          return booking;
        });
        dispatch(bookingListAction.setBookingList(mergedBookings));
        sessionStorage.setItem('apiKey', bookings.api_key);
        if (mergedBookings.length > 0) {
          skipBookingAuth();
          openModalLocationService();
        }
        else newBookingAuth();
      }
    } catch (error) {
      alertFail(error.message, 3);
    } finally {
      setIsLoading(false);
    }
  }

  const selectBooking = (booking) => {
    try {
      dispatch(bookingSelectAction.selectBooking(booking));
    } catch (error) {
      throw error;
    }
  };

  const verifyCurrentLocation = () => {
    return new Promise(function(resolve, reject) {
      const elysianGangchonLat = 37.822073;
      const elysianGangchonLng = 127.589852;
      const centerRadiusOfElysianGangchon = 10000; // 엘리시안 강촌리조트 내부 중심으로 부터 반경: 1000m
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const currentLat = position.coords.latitude;
          const currentLng = position.coords.longitude;
          const currentDistance = getDistanceFromCurrentLocationToDestination(currentLat, currentLng, elysianGangchonLat, elysianGangchonLng);
          if (currentDistance - centerRadiusOfElysianGangchon < 0 || process.env.REACT_APP_ENV === 'development') resolve();
          else reject({code: 0, message: '현재 사용자 위치는 엘리시안 강촌리조트 내부가 아닙니다.'});
        }, (error) => {
          const formatErrorMessage = {
            0: '알 수 없는 에러 입니다.',
            1: '위치 서비스가 꺼져 있습니다. 위치 서비스를 켜 주세요.',
            2: '위치를 찾을 수 없습니다.',
            3: '요청 응답 시간이 초과 되었습니다. 재요청 해주세요.'
          };
          reject({code: 0, message: formatErrorMessage[error.code] || error.message});
        });
      } else reject({code: 0, message: '이 브라우저에서는 Geolocation이 지원되지 않습니다.'});
    });
  };

  function getDistanceFromCurrentLocationToDestination(currentLat, currentLng, destinationLat, destinationLng) {
    var R = 6371; // Radius of the earth in km
    var dLat = toRadians(destinationLat - currentLat);
    var dLng = toRadians(destinationLng - currentLng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(currentLat)) * Math.cos(toRadians(destinationLat)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c * 1000; // Distance in m
    return d;
  }

  function toRadians(deg) {
    return deg * (Math.PI / 180);
  }

  const openModalLocationService = () => {
    setIsModalLocationServiceVisible(true);
  };

  const closeModalLocationService = () => {
    setIsModalLocationServiceVisible(false);
  };

  const skipBookingAuth = () => {
    dispatch(userConfigAction.setIsSkipBookingAuth(true));
  };

  const newBookingAuth = () => {
    dispatch(userConfigAction.setIsSkipBookingAuth(false));
  };

  useEffect(() => {
    if (name && phoneNumber) listBooking();
    else setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Spin spinning={isLoading} tip="Loading...">
        <MainLayout
          history={history}
          ContentBody={(
            <BookingList
              isOpenModalLocationService={isOpenModalLocationService}
              closeModalLocationService={closeModalLocationService}
              bookingListItems={bookingListItems}
              verifyCurrentLocation={verifyCurrentLocation}
              selectBooking={selectBooking}
              newBookingAuth={newBookingAuth}
              history={history}
            />
          )}
        />
      </Spin>
    </Fragment>
  );
};

export default BookingListContainer;