import { combineReducers } from 'redux';
import bookingList from './BookingListReducer';
import bookingSelect from './BookingSelectReducer';
import checkinRequest from './CheckinRequestReducer';
import locationServiceConfig from './LocationServiceConfigReducer';
import userConfig from './UserConfigReducer';

const rootReducer = combineReducers({
  bookingList,
  bookingSelect,
  checkinRequest,
  locationServiceConfig,
  userConfig,
});

export default rootReducer;
