import React, { Fragment } from 'react';
import { Button } from 'antd';
import MainLayout from '../layouts/MainLayout';

const Main = ({
  history,
}) => {
  const goBookingAuthPage = () => {
    history.push('/booking/auth');
  };

  return (
    <MainLayout
      history={history}
      ContentBody={(
        <Fragment>
          <div className='mt-30 ta-center pt-20'>
            <img className='content-main-logo' src='../../assets/images/content_main_logo.png' alt='main-logo'/>
          </div>
          <p className='mt-30 ta-center fs-16 c-darkgray bold wave'>
            엘리시안 강촌리조트
            <span className='ws-p'> 비</span>
            <span>대</span>
            <span>면</span>
            <span className='ws-p'> 체</span>
            <span>크</span>
            <span className='ws-p'>인 </span>
            서비스
          </p>
          <p className='ta-center m-10 c-gray ws-pl wb-ka'>비대면 체크인 서비스를 이용하기 위해<br/>위치정보가 필요 합니다.</p>
          <Button type='primary' size='large' className='mt-45 br-2 basic-button green' onClick={goBookingAuthPage}>예약 조회</Button>
        </Fragment>
      )}
    />
  );
};

export default Main;