import React, { Fragment } from 'react';
import { Form, Button, Input } from 'antd';
import { UserOutlined, PhoneOutlined } from '@ant-design/icons';
import { alertFail } from '../Alert';

const BookingAuth = ({
  name,
  phoneNumber,
  setBookingListInputItem,
  history,
}) => {
  const goPrevPage = () => {
    history.push('/');
  };

  const goNextPage = () => {
    try {
      if (!name || !phoneNumber) alertFail('입력 정보를 확인해주세요.', 3);
      else if (!validatePhoneNumber(phoneNumber)) alertFail('전화번호는 숫자만 입력해주세요.', 3);
      else history.push('/booking/list');
    } catch (error) {
      alertFail(error.message, 3);
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^[0-9]*$/;
    return regex.test(phoneNumber);
  };

  return (
    <Fragment>
      <p className='ta-center m-10 mt-30 pt-10 fs-16 c-darkgray bold'>예약 확인 하기</p>
      <p className='ta-center m-10 c-gray ws-pl wb-ka'>
        객실 예약에 입력하셨던 예약자명과 전화번호를 입력 하시면 비대면 체크인 및 객실키를 발급 받을 수 있습니다.
      </p>
      <Form>
        <Form.Item name='name' rules={[{required: true, message: '예약자명을 입력해주세요'}]}>
          <Input
            className='mt-20 basic-input'
            size='large'
            allowClear
            prefix={<UserOutlined className='c-lightgray'/>}
            placeholder='예약자명'
            value={name}
            onChange={(e) => setBookingListInputItem({phoneNumber, name: e.target.value})}
          />
        </Form.Item>
        <Form.Item name='phoneNumber' rules={[{required: true, message: '전화번호를 입력해주세요'}]}>
          <Input
            className='basic-input'
            type='tel'
            size='large'
            maxLength='11'
            allowClear
            prefix={<PhoneOutlined className='c-lightgray'/>}
            placeholder='전화번호 (숫자만 입력해주세요.)'
            value={phoneNumber}
            onChange={(e) => setBookingListInputItem({name, phoneNumber: e.target.value})}
          />
        </Form.Item>
        <Form.Item>
          <Button type='primary' size='large' className='mt-20 br-2 basic-button green' onClick={goNextPage}>
            예약 조회하기
          </Button>
          <Button type='primary' size='large' className='mt-10 br-2 basic-button gray' onClick={goPrevPage}>
            뒤로가기
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default BookingAuth;