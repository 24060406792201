import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../components/layouts/MainLayout';
import BookingAuth from '../components/views/BookingAuth';
import { userConfigAction } from '../stores/actions';

const BookingAuthContainer = ({
  history,
}) => {
  const dispatch = useDispatch();
  const { name, phoneNumber, isSkipBookingAuth } = useSelector(state => state.userConfig);

  useEffect(() => {
    if (isSkipBookingAuth) history.replace('/booking/list');
    else dispatch(userConfigAction.setBookingAuthRequest({
      name: '',
      phoneNumber: '',
    }));
  }, [dispatch, history, isSkipBookingAuth]);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <BookingAuth
            name={name}
            phoneNumber={phoneNumber}
            setBookingListInputItem={(state) => dispatch(userConfigAction.setBookingAuthRequest(state))}
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default BookingAuthContainer;