import React, { Fragment, useState } from 'react';
import { Form, Button, Input, Cascader, Select } from 'antd';
import ModalRoomDetail from './RoomDetail';
import { alertFail } from '../Alert';

const CheckIn = ({
  name,
  phoneNumber,
  personCount,
  roomNumber,
  setCheckinInputItem,
  bookingItem,
  splitedRoomList,
  selectRoom,
  history,
}) => {
  const [ additionalRoomInfo, setAdditionalRoomInfo ] = useState('');
  const [ isVisibleRoomList, setIsVisibleRoomList ] = useState(false);
  const [ isVisibleRoomDetail, setIsVisibleRoomDetail ] = useState(false);
  const [ selectedRoom, setSelectedRoom ] = useState({});

  const goPrevPage = () => {
    history.replace('/booking/list');
  };

  const goNextPage = () => {
    if (!name || !phoneNumber || !personCount || !roomNumber) alertFail('입력 정보를 확인해주세요.', 3);
    else if (!validatePhoneNumber(phoneNumber)) alertFail('전화번호는 숫자만 입력해주세요.', 3);
    else history.replace('/checkin/agreement');
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^[0-9]*$/;
    return regex.test(phoneNumber);
  };

  const changeRoom = (roomNumber) => {
    if (!roomNumber) {
      setCheckinInputItem({roomNumber: ''});
      setAdditionalRoomInfo('객실을 선택 해주세요');
    }
    else {
      const room = selectRoom(roomNumber);
      setSelectedRoom(room);
      setIsVisibleRoomDetail(true);
    }
  };

  const confirmRoomAfterSelect = (isVisible) => {
    if (!isVisibleRoomList) setIsVisibleRoomList(true);
    else if(!isVisible && !isVisibleRoomDetail) setIsVisibleRoomList(false);
  };

  const selectRoomAfterConfirm = () => {
    setCheckinInputItem({roomNumber: selectedRoom.room_no});
    setAdditionalRoomInfo(`${selectedRoom.room_type_name} ${selectedRoom.room_view_name ? ', ' + selectedRoom.room_view_name : ''} ${selectedRoom.extra_charge ? '+ ' + formatExtraCharge(selectedRoom.extra_charge) + '원' : ''}`);
    setIsVisibleRoomDetail(false);
    setIsVisibleRoomList(false);
  };

  const cancelRoomAfterConfirm = () => {
    setIsVisibleRoomDetail(false);
  };

  const formatExtraCharge = (extraCharge) => {
    const formatter = new Intl.NumberFormat('ko-KR', {
      currency: 'KPW',
    });
    return formatter.format(extraCharge);
  };

  return (
    <Fragment>
      <p className='ta-center m-10 mt-30 pt-10 fs-16 c-darkgray bold'>체크인</p>
      <Form className='inline-label-input'>
        <Form.Item
          className='inline-item'
          name='name'
          label='투수객명'
          colon={false}
          initialValue={bookingItem.user_name || ''}
          rules={[{required: true, message: '투수객명을 입력해주세요'}]}
        >
          <Input
            className='basic-input'
            size='large'
            allowClear
            placeholder='투수객명을 입력해주세요'
            value={name}
            onChange={(e) => setCheckinInputItem({name: e.target.value})}
          />
        </Form.Item>
        <Form.Item
          className='inline-item'
          name='phoneNumber'
          label='전화번호'
          colon={false}
          initialValue={bookingItem.phone_number || ''}
          rules={[{required: true, message: '전화번호를 입력해주세요'}]}
        >
          <Input
            className='basic-input'
            type='tel'
            size='large'
            maxLength={11}
            allowClear
            placeholder='전화번호 (숫자만 입력해주세요.)'
            value={phoneNumber}
            onChange={(e) => setCheckinInputItem({phoneNumber: e.target.value})}
          />
        </Form.Item>
        <Form.Item
          className='inline-item mb-0'
          name='roomNumber'
          label='객실이름'
          colon={false}
          help={additionalRoomInfo}
          rules={[{required: true, message: '객실을 선택 해주세요'}]}
        >
          <Cascader
            className='basic-input'
            size='large'
            options={splitedRoomList}
            placeholder='객실을 선택 해주세요'
            popupVisible={isVisibleRoomList}
            displayRender={() => roomNumber}
            onPopupVisibleChange={(value) => confirmRoomAfterSelect(value)}
            value={roomNumber}
            onChange={(value) => changeRoom(value[1])}
          />
        </Form.Item>
        <Form.Item
          className='inline-item'
          name='personCount'
          label='입실인원'
          colon={false}
          rules={[{required: true, message: '입실인원을 입력해주세요'}]}
        >
          <Select
            className='basic-input'
            size='large'
            allowClear
            placeholder='입실인원을 입력해주세요'
            value={personCount}
            onChange={(value) => setCheckinInputItem({personCount: value})}
          >
            <Select.Option value={1}>1</Select.Option>
            <Select.Option value={2}>2</Select.Option>
            <Select.Option value={3}>3</Select.Option>
            <Select.Option value={4}>4</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' size='large' className='mt-20 br-2 basic-button green' onClick={goNextPage}>
            확인
          </Button>
          <Button type='primary' size='large' className='mt-10 br-2 basic-button gray' onClick={goPrevPage}>
            뒤로가기
          </Button>
        </Form.Item>
      </Form>
      <p className='mt-38 mb-38'>*코로나19 방역지침에 의해 객실 투숙은 4인까지 가능합니다.
        동거가족, 백신완료자  포함으로 정원 內 투숙 시 증빙 제출이 필요하며,
        스마트 체크인이 불가합니다.
        콘도 프런트에서 체크인 부탁드립니다.
      </p>
      <ModalRoomDetail data={selectedRoom} isOpen={isVisibleRoomDetail} onSelect={selectRoomAfterConfirm} onCancel={cancelRoomAfterConfirm}/>
    </Fragment>
  );
};

export default CheckIn;