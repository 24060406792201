import React, { Fragment, useState, useEffect } from 'react';
import { Spin } from 'antd';
import MainLayout from '../components/layouts/MainLayout';
import Covid19Questionnaire from '../components/views/Covid19Questionnaire';
import * as api from '../api';

const Covid19QuestionnaireContainer = ({
  history,
}) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const rsvNo = history.location.state.rsvNo;
  const roomNumber = history.location.state.roomNumber;
  const personCount = history.location.state.personCount;
  const [ sendQuestionnaireRequestParams, setSendQuestionnaireRequestParams ] = useState({
    guestName1: '',
    guestBirthday1: '',
    guestName2: '',
    guestBirthday2: '',
    guestName3: '',
    guestBirthday3: '',
    guestName4: '',
    guestBirthday4: '',
    question1: '',
    question2: '',
    question3: '',
    question4: '',
  });

  const changeSendQuestionnaireRequestParams = (paramToChange) => {
    setSendQuestionnaireRequestParams({
      ...sendQuestionnaireRequestParams,
      ...paramToChange,
    });
  };

  const sendQuestionnaire = async () => {
    try {
      return await api.sendQuestionnaire({rsvNo, roomNumber, ...sendQuestionnaireRequestParams});
    } catch (error) {
      throw error;
    }
  };

  const issueKey = async () => {
    try {
      return await api.issueMobilekey({rsvNo, roomNumber});
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (!rsvNo || !roomNumber || !personCount) history.replace('/booking/list');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Spin spinning={isLoading} tip="Loading...">
        <MainLayout
          history={history}
          ContentBody={(
            <Covid19Questionnaire
              roomNumber={roomNumber}
              personCount={personCount}
              sendQuestionnaireRequestParams={sendQuestionnaireRequestParams}
              setIsLoading={setIsLoading}
              changeSendQuestionnaireRequestParams={changeSendQuestionnaireRequestParams}
              sendQuestionnaire={sendQuestionnaire}
              issueKey={issueKey}
              history={history}
            />
          )}
        />
      </Spin>
    </Fragment>
  );
};

export default Covid19QuestionnaireContainer;