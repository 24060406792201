import React, { Fragment } from 'react';
import { Layout } from 'antd';

const CustomFooter = () => {
  const { Footer } = Layout;

  return (
    <Fragment>
      <Footer className='footer-wrapper'>ⓒImGATE</Footer>
    </Fragment>
  );
};

export default CustomFooter;