import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../components/layouts/MainLayout';
import MarketingAgreement from '../components/views/MarketingAgreement';
import * as api from '../api';

const MarketingAgreementContainer = ({
  history,
}) => {
  const { bookingItem } = useSelector(state => state.bookingSelect);
  const { name, phoneNumber, personCount, roomNumber } = useSelector(state => state.checkinRequest);

  const checkIn = async (isMarketing) => {
    try {
      return await api.checkIn({
        rsvNo: bookingItem.rsv_no,
        name,
        phoneNumber,
        personCount,
        roomNumber,
        isMarketing,
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (!bookingItem.rsv_no || !name || !phoneNumber || !personCount || !roomNumber) history.replace('/checkin/room');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <MarketingAgreement
            checkIn={checkIn}
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default MarketingAgreementContainer;