import * as types from '../constants';

const initialState = {
  isOpen: true,
};

const LocationServiceConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_OPENABLE_MODAL_CONFIG:
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
};

export default LocationServiceConfigReducer;