import React, { Fragment } from 'react';
import { Button, Modal } from 'antd';

const ModalRoomDetail = ({
  data,
  isOpen,
  onSelect,
  onCancel,
}) => {
    const formatExtraCharge = (extraCharge) => {
      const formatter = new Intl.NumberFormat('ko-KR', {
        currency: 'KPW',
      });
      return formatter.format(extraCharge);
    };

  return (
    <Fragment>
      <Modal visible={isOpen} centered footer={null} maskClosable={false} closable={false}>
        <p className='m-10 pt-20 ta-center bold'>선택하신 객실이 맞습니까?</p>
        <p className='m-0 mt-10 ml-35 mr-35'>{`객실이름: ${data.room_no}`}</p>
        <p className='m-0 ml-35 mr-35'>{`객실타입: ${data.room_type_name}`}</p>
        <p className='m-0 ml-35 mr-35'>{data.room_view_name ? `객실전망: ${data.room_view_name}` : ''}</p>
        {data.room_detail && 
          <p className='m-0 ml-35 mr-35'>{`객실구성: ${data.room_detail}`}</p>
        }
        <p className='m-0 ml-35 mr-35 c-blue'>{data.extra_charge ? `추가요금: ${formatExtraCharge(data.extra_charge)}원` : ''}</p>
        <Button type='primary' size='large' className='mt-30 mb-10 br-2 basic-button green' onClick={onSelect}>
          확인
        </Button>
        <Button type='primary' size='large' className='mt-10 mb-10 br-2 basic-button gray' onClick={onCancel}>
          취소
        </Button>
      </Modal>
    </Fragment>
  );
};

export default ModalRoomDetail;