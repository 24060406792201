import * as types from '../constants';

const initialState = {
  rsvNo: '',
  name: '',
  phoneNumber: '',
  personCount: '',
  roomNumber: '',
};

const CheckinRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CHECKIN_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default CheckinRequestReducer;