import React, { Fragment } from 'react';
import { Form, Button, Input, Radio } from 'antd';
import { alertSuccess, alertFail } from '../Alert';

const Covid19Questionnaire = ({
  roomNumber,
  personCount,
  sendQuestionnaireRequestParams,
  setIsLoading,
  changeSendQuestionnaireRequestParams,
  sendQuestionnaire,
  issueKey,
  history,
}) => {
  const goPrevPage = () => {
    history.push('/booking/list');
  };

  const onSubmit = async () => {
    try {
      validateSendQuestionnaireRequestParams(sendQuestionnaireRequestParams);
      setIsLoading(true);
      const { data: sendQuestionnaireRes } = await sendQuestionnaire();
      if (sendQuestionnaireRes.return !== '0') alertFail(sendQuestionnaireRes.msg, 3);
      else {
        const { data: issueKeyRes } = await issueKey();
        if (issueKeyRes.return !== '0') alertFail(issueKeyRes.msg, 3);
        else {
          alertSuccess('키발급이 완료 되었습니다.');
          setTimeout(() => {
            history.replace('/booking/list');
          }, 1000);
        }
      }
    } catch (error) {
      alertFail(error.message, 5);
    } finally {
      setIsLoading(false);
    }
  };

  const validateSendQuestionnaireRequestParams = (params) => {
    if (personCount === '1' && (!params.guestName1 || !params.guestBirthday1)) throw new Error('모든 입실 고객님의 정보를 입력하셔야 합니다.');
    else if (personCount === '2' && (!params.guestName1 || !params.guestBirthday1 || !params.guestName2 || !params.guestBirthday2)) throw new Error('모든 입실 고객님의 정보를 입력하셔야 합니다.');
    else if (personCount === '3' && (!params.guestName1 || !params.guestBirthday1 || !params.guestName2 || !params.guestBirthday2 || !params.guestName3 || !params.guestBirthday3)) throw new Error('모든 입실 고객님의 정보를 입력하셔야 합니다.');
    else if (personCount === '4' && (!params.guestName1 || !params.guestBirthday1 || !params.guestName2 || !params.guestBirthday2 || !params.guestName3 || !params.guestBirthday3 || !params.guestName4 || !params.guestBirthday4)) throw new Error('모든 입실 고객님의 정보를 입력하셔야 합니다.');
    else if (!params.question1 || !params.question2 || !params.question3 || !params.question4) throw new Error('모든 입실 고객님의 정보를 입력하셔야 합니다.');
    else if (params.question1 === 'Y' || params.question2 === 'Y' || params.question3 === 'Y' || params.question4 === 'Y') throw new Error('체크인을 진행할 수 없습니다. 프론트로 연락주시기 바랍니다. 033-260-2660');
    else return;
  };

  return (
    <Fragment>
      <p className='ta-center m-10 mt-30 pt-10 fs-16 c-darkgray bold'>코로나-19[COVID-19] 문진표</p>
      <Form>
        <Form.Item className='mt-15 mb-0'>
          <p className='mb-0 bold'>객실호수: {roomNumber}</p>
        </Form.Item>
        <p className='mb-0 bold'>투숙객 인적사항</p>
        <div className='fs-14 c-darkgray'>
          <div className='d-inline-flex w-50'>
            <p className='mt-3 mb-3'>성명</p>
          </div>
          <div className='d-inline-flex w-50'>
            <p className='mt-3 mb-3'>생년월일</p>
          </div>
        </div>
        <div className='form-list-item'>
          <Form.Item className='mr-10 mb-10'>
            <Input
              placeholder='ex) 홍길동'
              size='large'
              allowClear
              value={sendQuestionnaireRequestParams.guestName1}
              onChange={(e) => changeSendQuestionnaireRequestParams({guestName1: e.target.value})}
            />
          </Form.Item>
          <Form.Item className='mr-10 mb-10'>
            <Input
              placeholder='ex) 20210101'
              type='tel'
              maxLength={8}
              size='large'
              allowClear
              value={sendQuestionnaireRequestParams.guestBirthday1}
              onChange={(e) => changeSendQuestionnaireRequestParams({guestBirthday1: e.target.value})}
            />
          </Form.Item>
        </div>
        <div className='form-list-item' hidden={['1'].includes(personCount)}>
          <Form.Item className='mr-10 mb-10'>
            <Input
              placeholder='ex) 홍길동'
              size='large'
              allowClear
              value={sendQuestionnaireRequestParams.guestName2}
              onChange={(e) => changeSendQuestionnaireRequestParams({guestName2: e.target.value})}
            />
          </Form.Item>
          <Form.Item className='mr-10 mb-10'>
            <Input
              placeholder='ex) 20210101'
              type='tel'
              maxLength={8}
              size='large'
              allowClear
              value={sendQuestionnaireRequestParams.guestBirthday2}
              onChange={(e) => changeSendQuestionnaireRequestParams({guestBirthday2: e.target.value})}
            />
          </Form.Item>
        </div>
        <div className='form-list-item' hidden={['1', '2'].includes(personCount)}>
          <Form.Item className='mr-10 mb-10'>
            <Input
              placeholder='ex) 홍길동'
              size='large'
              allowClear
              value={sendQuestionnaireRequestParams.guestName3}
              onChange={(e) => changeSendQuestionnaireRequestParams({guestName3: e.target.value})}
            />
          </Form.Item>
          <Form.Item className='mr-10 mb-10'>
            <Input
              placeholder='ex) 20210101'
              type='tel'
              maxLength={8}
              size='large'
              allowClear
              value={sendQuestionnaireRequestParams.guestBirthday3}
              onChange={(e) => changeSendQuestionnaireRequestParams({guestBirthday3: e.target.value})}
            />
          </Form.Item>
        </div>
        <div className='form-list-item' hidden={['1', '2', '3'].includes(personCount)}>
          <Form.Item className='mr-10 mb-10'>
            <Input
              placeholder='ex) 홍길동'
              size='large'
              allowClear
              value={sendQuestionnaireRequestParams.guestName4}
              onChange={(e) => changeSendQuestionnaireRequestParams({guestName4: e.target.value})}
            />
          </Form.Item>
          <Form.Item className='mr-10 mb-10'>
            <Input
              placeholder='ex) 20210101'
              type='tel'
              maxLength={8}
              size='large'
              allowClear
              value={sendQuestionnaireRequestParams.guestBirthday4}
              onChange={(e) => changeSendQuestionnaireRequestParams({guestBirthday4: e.target.value})}
            />
          </Form.Item>
        </div>
        <p className='mb-25'>투숙 인원수만큼 이름을 기재해 주십시오.</p>
        <div className='mt-15 mb-15 b-1-solid'>
          <Form.Item className='questionnaire'>
            <p className='mb-0 fs-14 c-darkgray bold ws-pl wb-ka'>1. 작성자께서는(일행) 최근 2주 이내 코로나 바이러스 감염 확진자 또는 밀접접촉자를 만난 적이 있습니까?</p>
            <Radio.Group
              size='large'
              value={sendQuestionnaireRequestParams.question1}
              onChange={(e) => changeSendQuestionnaireRequestParams({question1: e.target.value})}
            >
              <Radio value={'Y'}>예</Radio>
              <Radio value={'N'}>아니오</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item className='questionnaire'>
            <p className='mb-0 fs-14 c-darkgray bold ws-pl wb-ka'>2. 작성자께서는(일행) 최근 2주 이내 감염 확진자가 발생한 지역(국내 및 해외 포함) 및 기타 행사에 방문한 적이 있습니까?</p>
            <Radio.Group
              size='large'
              value={sendQuestionnaireRequestParams.question2}
              onChange={(e) => changeSendQuestionnaireRequestParams({question2: e.target.value})}
            >
              <Radio value={'Y'}>예</Radio>
              <Radio value={'N'}>아니오</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item className='questionnaire'>
            <p className='mb-0 fs-14 c-darkgray bold ws-pl wb-ka'>3. 작성자께서는(일행) 질병관리본부로부터 자가격리 대상자라는 통보를 받았습니까?</p>
            <Radio.Group
              size='large'
              value={sendQuestionnaireRequestParams.question3}
              onChange={(e) => changeSendQuestionnaireRequestParams({question3: e.target.value})}
            >
              <Radio value={'Y'}>예</Radio>
              <Radio value={'N'}>아니오</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item className='questionnaire bb-unset'>
            <p className='mb-0 fs-14 c-darkgray bold ws-pl wb-ka'>4. 작성자께서는(일행) 최근 2주 이내 발열(37.5° 이상), 기침, 인후통, 근육통(몸살, 감기) 호흡곤란과 같은 증상이 있었습니까?</p>
            <Radio.Group
              size='large'
              value={sendQuestionnaireRequestParams.question4}
              onChange={(e) => changeSendQuestionnaireRequestParams({question4: e.target.value})}
            >
              <Radio value={'Y'}>예</Radio>
              <Radio value={'N'}>아니오</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item>
          <p className='mb-0 ws-pl wb-ka'>* 위 사항을 거짓으로 작성, 인원초과 위반 입실 시 과태료에 대한 구상권 청구 및 법적조치(손해배상)를 받을 수 있습니다.</p>
          <div className='mt-15 b-1-solid ws-pl wb-ka'>
            <p className='mt-3 mb-3 c-darkgray bold ta-center'>개인정보 수집, 활용 동의서</p>
          </div>
          <div className='bl-1-solid br-1-solid bb-1-solid fs-12 c-gray ws-pl wb-ka'>
            <div className='d-inline-flex w-50 br-1-solid'>
              <p className='mt-3 mb-3 ml-5 mr-5'>수집하는 개인정보의 항목</p>
            </div>
            <div className='d-inline-flex w-50'>
              <p className='mt-3 mb-3 ml-5 mr-5'>전화번호, 생년월일</p>
            </div>
          </div>
          <div className='bl-1-solid br-1-solid bb-1-solid fs-12 c-gray ws-pl wb-ka'>
            <div className='d-inline-flex w-50 br-1-solid'>
              <p className='mt-3 mb-3 ml-5 mr-5'>개인정보의 수집 및 이용목적</p>
            </div>
            <div className='d-inline-flex w-50'>
              <p className='mt-3 mb-3 ml-5 mr-5'>코로나19 의심환자 파악</p>
            </div>
          </div>
          <div className='bl-1-solid br-1-solid bb-1-solid fs-12 c-gray ws-pl wb-ka'>
            <div className='d-inline-flex w-50 br-1-solid'>
              <p className='mt-3 mb-3 ml-5 mr-5'>개인정보의 보유 및 이용 기간</p>
            </div>
            <div className='d-inline-flex w-50'>
              <p className='mt-3 mb-3 ml-5 mr-5'>작성일로부터 1개월</p>
            </div>
          </div>
          <div className='bl-1-solid br-1-solid bb-1-solid fs-12 c-gray ws-pl wb-ka'>
            <div className='d-inline-flex w-50-1'>
              <p className='mt-3 mb-3 ml-5 mr-5'>개인정보 제공 동의 거부 권리 및 동의 거부에 따를 불이익 내용 또는 제한 사항</p>
            </div>
            <div className='d-inline-flex w-50 bl-1-solid'>
              <p className='mt-3 mb-3 ml-5 mr-5'>콘도 투숙을 위해 필요한 정보로 상기 내용에 대하여 동의하지 않는 경우 입실하실 수 없음을 알려드립니다.</p>
            </div>
          </div>
          <div className='d-inline-flex bl-1-solid br-1-solid bb-1-solid fs-12 c-gray ws-pl wb-ka'>
            <p className='mt-3 mb-3 ml-5 mr-5'>「개인정보보호법」 등 관련 법규에 의거하여 상기 본인은 위 사항에 대하여 충분히 인지 하였으며 이와 같이 개인정보 수집 및 활용에 동의합니다.</p>
          </div>
        </Form.Item>
        <Form.Item>
          <Button type='primary' size='large' className='br-2 basic-button green' onClick={onSubmit}>
            제출하고, 객실 출입키 발급받기
          </Button>
          <Button type='primary' size='large' className='mt-10 br-2 basic-button gray' onClick={goPrevPage}>
            뒤로가기
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default Covid19Questionnaire;