import * as types from '../constants';

const initialState = {
  bookingItem: {},
};

const BookingSelectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_BOOKING:
      return {
        ...state,
        bookingItem: action.payload,
      };
    default:
      return state;
  }
};

export default BookingSelectReducer;