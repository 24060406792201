import Main from '../components/views/Main';
import BookingAuth from '../containers/BookingAuthContainer';
import BookingList from '../containers/BookingListContainer';
import CheckIn from '../containers/CheckInContainer';
import MarketingAgreement from '../containers/MarketingAgreementContainer';
import Covid19Questionnaire from '../containers/Covid19QuestionnaireContainer';

const mainRoutes = {
  path: '/',
  name: 'Main',
  component: Main,
};

const bookingAuthRoutes = {
  path: '/booking/auth',
  name: 'Booking Auth',
  component: BookingAuth,
};

const bookingListRoutes = {
  path: '/booking/list',
  name: 'Booking List',
  component: BookingList,
};

const checkInRoutes = {
  path: '/checkin/room',
  name: 'Check In',
  component: CheckIn,
};

const marketingAgreementRoutes = {
  path: '/checkin/agreement',
  name: 'Marketing Agreement',
  component: MarketingAgreement,
};

const covid19QuestionnaireRoutes = {
  path: '/key/questionnaire',
  name: 'Covid-19 Questionnaire',
  component: Covid19Questionnaire,
};

export const routesList = [
  mainRoutes,
  bookingAuthRoutes,
  bookingListRoutes,
  checkInRoutes,
  marketingAgreementRoutes,
  covid19QuestionnaireRoutes,
];