import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Checkbox } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { locationServiceConfigAction } from '../../stores/actions';

const ModalLocationSerivce = ({
  isOpen,
  onClose,
}) => {
  const [ isChecked, setIsChecked ] = useState(false);
  const { isOpen: visible } = useSelector(state => state.locationServiceConfig);
  const dispatch = useDispatch();

  const convertIsChecked = () => {
    setIsChecked(!isChecked);
  };

  const confirm = () => {
    dispatch(locationServiceConfigAction.setOpenableModalConfig(!isChecked));
    onClose();
  };

  return (
    <Fragment>
      <Modal visible={isOpen && visible} onCancel={onClose} centered footer={null} maskClosable={false}>
        <p className='m-10 pt-20 ta-center fs-16 c-darkgray bold'><span className='c-blue'>위치 정보</span> 사용을 허용해 주세요</p>
        <div className='mt-30 mb-30 ta-center'>
          <EnvironmentOutlined className='modal-location-image'/>
        </div>
        <p className='m-10 ta-center c-gray'>
          엘리시안 강촌 비대면 체크인 서비스는  콘도 인근 5km이내 지역에서 가능 합니다.
          위치정보 활용에 동의해 주세요.
        </p>
        <Button type='primary' size='large' className='mt-30 mb-10 br-2 basic-button green' onClick={confirm}>
          확인
        </Button>
        <div className='w-100 ta-center'>
          <Checkbox onChange={convertIsChecked}>다시 보지 않기</Checkbox>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ModalLocationSerivce;