import React, { Fragment } from 'react';
import { Form, Button} from 'antd';
import moment from 'moment';
import { FileUnknownOutlined } from '@ant-design/icons';
import AffixRefresh from '../AffixRefresh';
import ModalLocationService from './LocationService';
import { alertFail } from '../Alert';

const BookingList = ({
  isOpenModalLocationService,
  closeModalLocationService,
  bookingListItems,
  verifyCurrentLocation,
  selectBooking,
  newBookingAuth,
  history,
}) => {
  const refresh = () => {
    window.location.replace('/booking/list');
  };

  const moveToApp = () => {
    const appLink = 'https://elysiangangchon.page.link/key';
    window.open(appLink);
  };

  const goHomePage = () => {
    history.push('/');
  };

  const goBookingAuthPage = () => {
    newBookingAuth();
    history.push('/booking/auth');
  };

  const verifyCheckinTime = (checkinDate, checkinTime) => {
    const mergedCheckinDate = `${checkinDate}T${checkinTime.replace(':', '')}`;
    return moment(mergedCheckinDate) < moment();
  };

  const onCheckIn = async (booking) => {
    try {
      const isVerified = verifyCheckinTime(booking.checkin_date, booking.checkin_time);
      if (!isVerified) alertFail('체크인 가능 시간이 아닙니다.', 3);
      else {
        await verifyCurrentLocation();
        selectBooking(booking);
        history.push('/checkin/room');
      }
    } catch (error) {
      alertFail(error.message, 3);
    }
  };

  const onIssueKey = async (rsvNo, roomNumber, personCount) => {
    history.push('/key/questionnaire', {rsvNo, roomNumber, personCount});
  };

  const setBookingPeriod = (checkinDate, checkoutDate) => {
    return `${moment(checkinDate).format('YYYY.M.D')} ~ ${moment(checkoutDate).format('YYYY.M.D')}`;
  };

  const formatCheckinTime = (checkinDate, checkinTime) => {
    const mergedCheckinDate = `${checkinDate}T${checkinTime.replace(':', '')}`;
    return `${moment(mergedCheckinDate).format('A') === 'AM' ? '오전' : '오후'} ${moment(mergedCheckinDate).format('h')}시 ${moment(mergedCheckinDate).format('mm')}분`;
  };

  return (
    <Fragment>
      {bookingListItems.length > 0 ? (
        <Fragment>
          <p className='m-10 pt-30 ta-center fs-16 c-darkgray bold'>예약 목록</p>
          {bookingListItems.map((item, index) => item.room_no ?
            (
              <div className='form-card-wrap' key={index}>
                <Form className='card'>
                  <Form.Item label='객실정보' colon={false}>
                    <p className='ml-30 mb-0 bold'>{item.room_no}</p>
                  </Form.Item>
                  <Form.Item label='번호키' colon={false}>
                    <p className='ml-42 mb-0 bold'>{item.pincode ? `${item.pincode}*` : '미발급'}</p>
                  </Form.Item>
                  <Form.Item label='모바일키' colon={false}>
                    <p className='ml-30 mb-0 bold'>{item.mobile_key || '미발급'}</p>
                  </Form.Item>
                  <div className='ml-35 fs-13' hidden={!item.mobile_key}>
                    <p className='mb-0'>1. <span className='pointer c-blue bold underline' onClick={moveToApp}>모바일키 전용앱 다운 받기</span></p>
                    <p className='mb-0'>2. <b>{item.mobile_key}</b> 입력하기</p>
                    <p className='mb-0'>3. 객실 도어락 터치해서 도어락 열기</p>
                  </div>
                  <Form.Item label='투숙기간' colon={false}>
                    <p className='ml-30 mb-0 bold'>{setBookingPeriod(item.checkin_date, item.checkout_date)}</p>
                  </Form.Item>
                  <p className='ml-20 mb-10 fs-12'>객실 변경 시에는 프런트로 방문해주시기 바랍니다.</p>
                  <Form.Item className='mt-10' hidden={item.mobile_key || item.pincode}>
                    <Button type='primary' size='large' className='mb-10 br-2 basic-button green' onClick={() => onIssueKey(item.rsv_no, item.room_no, item.person_count)}>
                      객실 출입키 요청하기
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            ) : (
              <div className='form-card-wrap' key={index}>
                <Form className='card'>
                  <Form.Item label='예약번호' colon={false}>
                    <p className='ml-30 mb-0 bold'>{item.rsv_no}</p>
                  </Form.Item>
                  <Form.Item label='투숙기간' colon={false}>
                    <p className='ml-30 mb-0 bold'>{setBookingPeriod(item.checkin_date, item.checkout_date)}</p>
                  </Form.Item>
                  <Form.Item label='예약자명' colon={false}>
                    <p className='ml-30 mb-0 bold'>{item.user_name}</p>
                  </Form.Item>
                  <Form.Item help={`체크인은 ${formatCheckinTime(item.checkin_date, item.checkin_time)} 이후, 리조트 내부에서 가능합니다.`}>
                    <Button type='primary' size='large' className='mt-10 br-2 basic-button green' onClick={() => onCheckIn(item)}>
                      체크인하기
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            )
          )}
        </Fragment>
      ) : (
        <Fragment>
          <p className='m-10 pt-30 ta-center fs-16 c-darkgray bold'>예약 내역이 없습니다</p>
          <div className='no-list'>
            <FileUnknownOutlined />
          </div>
          <p className='m-10 mb-30 ta-center c-gray ws-pl wb-ka'>예약 내역을 찾을 수가 없습니다.<br/>다시 검색해 주세요.</p>
          <Button type='primary' size='large' className='w-100-40 m-auto mt-20 br-2 basic-button green' onClick={goBookingAuthPage}>새 조회하기</Button>
        </Fragment>
      )}
      <Button type='primary' size='large' className='w-100-40 m-auto mt-10 br-2 basic-button blue' onClick={goHomePage}>홈으로</Button>
      <AffixRefresh refresh={refresh}/>
      <ModalLocationService isOpen={isOpenModalLocationService} onClose={closeModalLocationService}/>
    </Fragment>
  );
};

export default BookingList;