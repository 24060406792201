import React, { Fragment } from 'react';
import { Form, Button } from 'antd';
import { alertFail } from '../Alert';

const MarketingAgreement = ({
  checkIn,
  history,
}) => {
  const onCheckIn = async (isMarketing) => {
    try {
      const { data: checkInRes } = await checkIn(isMarketing);
      if (checkInRes.return !== '0') alertFail(checkInRes.msg, 3);
      else history.replace('/booking/list');
    } catch (error) {
      alertFail(error.message, 3);
    }
  };

  return (
    <Fragment>
      <Form className='mt-20'>
        <Form.Item className='mb-0 wb-ka'>
          <p className='mb-0 pt-20 ta-center bold'>마케팅 동의 관련 내용</p>
          <p className='mt-20 mb-0 fs-11 ml-15 mr-15 bold'>1.개인정보의 수집 및 이용 목적</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'>GS건설은 고객님의 예약사항 확인 및 안내를 위하여 필요한 최소한의 범위 내에서 개인정보를 수집하고 있습니다.</p>
          <p className='mt-10 mb-0 fs-11 ml-15 mr-15 bold'>2.수집하는 개인정보의 항목</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'>{'1)엘리시안 강촌 콘도'}</p>
          <p className='mt-0 mb-0 ml-35 mr-15 fs-11'>- 필수 항목: 이용자명, 휴대폰번호</p>
          <p className='mt-0 mb-0 ml-35 mr-15 fs-11'>- 선택 항목: 없음</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'><br/>수집방법: 웹사이트에 고객이 직접 입력</p>
          <p className='mt-10 mb-0 fs-11 ml-15 mr-15 bold'>3.개인정보의 처리 및 보유기간</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'>GS건설은 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'>단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'><br/>보존 항목: 이름, 회원번호, 휴대폰번호, 예약정보</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'><br/>보존 근거: 소비자의 불만 또는 분쟁처리에 관한 기록</p>
          <p className='mt-0 mb-0 ml-35 mr-15 fs-11'>(전자상거래등에서의 소비자보호에 관한 법률)</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'><br/>보존 기간: 5년</p>
          <p className='mt-10 mb-0 fs-11 ml-15 mr-15 bold'>4.마케팅 활용 동의 (선택)</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'>마케팅/홍보를 위하여 귀하의 개인정보를 이용 하는데 동의 하십니까?</p>
          <p className='mt-0 mb-0 ml-25 mr-15 fs-11'>SMS 문자 수신(LMS,MMS) 동의 거부 시 할인 및 이벤트 정보 안내 서비스가 제한 됩니다.</p>
          {/* <div>
          <pre className='mt-20 fs-11'>
            <b>1.개인정보의 수집 및 이용 목적</b><br/>
            {`  GS건설은 고객님의 예약사항 확인 및 안내를 위하여 필요한 최소한의 범위 내에서 개인정보를 수집하고 있습니다.`}<br/><br/>
            <b>2.수집하는 개인정보의 항목</b><br/>
{`  1)엘리시안 강촌 콘도
    - 필수 항목: 이용자명, 휴대폰번호
    - 선택 항목: 없음

  수집방법: 웹사이트에 고객이 직접 입력`}<br/><br/>
            <b>3.개인정보의 처리 및 보유기간</b><br/>
{`  GS건설은 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
  단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

  보존 항목: 이름, 회원번호, 휴대폰번호, 예약정보

  보존 근거: 소비자의 불만 또는 분쟁처리에 관한 기록
          (전자상거래등에서의 소비자보호에 관한 법률)

  보존 기간: 5년`}<br/><br/>
            <b>4.마케팅 활용 동의 (선택)</b><br/>
{`  마케팅/홍보를 위하여 귀하의 개인정보를 이용 하는데 동의 하십니까?
  SMS 문자 수신(LMS,MMS) 동의 거부 시 할인 및 이벤트 정보 안내 서비스가 제한 됩니다.`}
          </pre>
          </div> */}
        </Form.Item>
        <Form.Item>
          <Button type='primary' size='large' className='mt-20 br-2 basic-button green' onClick={() => onCheckIn('Y')}>
            동의
          </Button>
          <Button type='primary' size='large' className='mt-10 br-2 basic-button green' onClick={() => onCheckIn('N')}>
            비동의
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default MarketingAgreement;