import * as types from '../constants';

const initialState = {
  name: '',
  phoneNumber: '',
  isSkipBookingAuth: false,
};

const UserConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BOOKING_AUTH_REQUEST:
      return {
        ...state,
        name: action.payload.name,
        phoneNumber: action.payload.phoneNumber,
      };
    case types.SET_SKIP_BOOKING_AUTH:
      return {
        ...state,
        isSkipBookingAuth: action.payload,
      };
    default:
      return state;
  }
};

export default UserConfigReducer;