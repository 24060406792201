import React, { Fragment, useState } from 'react';
import { Layout, Drawer } from 'antd';
import { CarryOutOutlined, HomeOutlined, MenuOutlined } from '@ant-design/icons';

const CustomHeader = ({
  history,
}) => {
  const { Header } = Layout;
  const [ isVisibleMenu, setIsVisibleMenu ] = useState(false);

  const showMenu = () => {
    setIsVisibleMenu(true);
  };

  const closeMenu = () => {
    setIsVisibleMenu(false);
  };

  const moveToPage = (url) => {
    closeMenu();
    history.push(url);
  }

  return (
    <Fragment>
      <Header className='header-wrapper'>
        <img className='header-logo' src='../assets/images/header_main_logo.png' alt='header-logo'/>
        <h1 className='header-title'>엘리시안 강촌리조트</h1>
        <div className='menu-bar'><MenuOutlined onClick={showMenu}/></div>
        <Drawer closable={true} onClose={closeMenu} visible={isVisibleMenu}>
          <p className='mt-45 fs-16 pointer hover-blue' onClick={() => moveToPage('/')}>
            <HomeOutlined className='mr-10'/>홈으로
          </p>
          <p className='mt-30 fs-16 pointer hover-blue' onClick={() => moveToPage('/booking/auth')}>
            <CarryOutOutlined className='mr-10'/>예약조회
          </p>
        </Drawer>
      </Header>
    </Fragment>
  );
};

export default CustomHeader;