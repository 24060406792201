import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../components/layouts/MainLayout';
import RoomSelect from '../components/views/CheckIn';
// import { alertFail } from '../components/Alert';
import * as api from '../api';
import { checkinRequestAction } from '../stores/actions';
import { alertFail } from '../components/Alert';

const CheckInContainer = ({
  history,
}) => {
  const dispatch = useDispatch();
  const { bookingItem } = useSelector(state => state.bookingSelect);
  const { name, phoneNumber, personCount, roomNumber } = useSelector(state => state.checkinRequest);
  const [ roomList, setRoomList ] = useState([]);
  const [ splitedRoomList, setSplitedRoomList ] = useState([
    {
      value: '1',
      label: '일반객실',
      children: [],
    },
    {
      value: '2',
      label: '전망객실',
      children: [],
    },
    {
      value: '3',
      label: '테마객실',
      children: [],
    },
  ]);

  const selectRoom = (roomNumber) => {
    try {
      let selectedRoom;
      roomList.map(room => {
        if (room.room_no === roomNumber) selectedRoom = room;
        return room;
      })
      return selectedRoom;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const listRoom = async () => {
      try {
        const { data: rooms } = await api.listRoom({
          rsvNo: bookingItem.rsv_no,
          condoPyungCode: bookingItem.condo_pyung_code,
        });
        if (rooms.return !== '0') {
          if (rooms.msg === '예약가능한 객실이 없습니다.') alertFail('스마트 체크인 가능 객실이 마감되었으므로 프런트에서 15시부터 대면 체크인 가능합니다.', 5);
          else alertFail(rooms.msg, 5);
        }
        else {
          const childrenSplitedRoomList = new Array(3).fill([]);
          rooms.data.map(room => childrenSplitedRoomList[parseInt(room.room_gubun) - 1] = [
            ...childrenSplitedRoomList[parseInt(room.room_gubun) - 1],
            {value: room.room_no, label: room.room_no},
          ]);
          setRoomList(rooms.data);
          setSplitedRoomList([
            {...splitedRoomList[0], children: childrenSplitedRoomList[0], disabled: !childrenSplitedRoomList[0].length},
            {...splitedRoomList[1], children: childrenSplitedRoomList[1], disabled: !childrenSplitedRoomList[1].length},
            {...splitedRoomList[2], children: childrenSplitedRoomList[2], disabled: !childrenSplitedRoomList[2].length},
          ]);
        }
      } catch (error) {
        throw error;
      }
    };
    if (bookingItem.rsv_no && bookingItem.condo_pyung_code) listRoom();
    dispatch(checkinRequestAction.setCheckinRequest({
      rsvNo: bookingItem.rsv_no || '',
      name: bookingItem.user_name || '',
      phoneNumber: bookingItem.phone_number || '',
      personCount: '',
      roomNumber: '',
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <RoomSelect
            name={name}
            phoneNumber={phoneNumber}
            personCount={personCount}
            roomNumber={roomNumber}
            setCheckinInputItem={(state) => dispatch(checkinRequestAction.setCheckinRequest(state))}
            bookingItem={bookingItem}
            splitedRoomList={splitedRoomList}
            selectRoom={selectRoom}
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default CheckInContainer;